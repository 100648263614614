<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <path
      d="m9.26,1.96c1.46,0,2.65,1.19,2.65,2.65v5.61c0,1.46-1.19,2.65-2.65,2.65H3.65c-1.46,0-2.65-1.19-2.65-2.65v-5.61c0-1.46,1.19-2.65,2.65-2.65h5.61m0-1H3.65C1.64.96,0,2.61,0,4.61v5.61C0,12.23,1.64,13.87,3.65,13.87h5.61c2,0,3.65-1.64,3.65-3.65v-5.61c0-2-1.64-3.65-3.65-3.65h0Z"
      style="fill: #999; stroke-width: 0px"
    />
    <polyline
      points="2.76 5.61 3.62 6.46 5.42 4.66"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="6.74"
      y1="5.98"
      x2="10.14"
      y2="5.98"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <polyline
      points="2.76 9.31 3.62 10.16 5.42 8.36"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="6.74"
      y1="9.68"
      x2="10.14"
      y2="9.68"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
